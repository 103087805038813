import React from "react";
import { StaticQuery, graphql } from "gatsby";
import HeaderComponent from "../components/common/header";
import ContactForm from "../components/common/contactForm";
import Footer from "../components/common/footer";
import { Helmet } from 'react-helmet';  // Import Helmet

const Contact = () => (
  <StaticQuery
    query={graphql`
      query ContactPageBannerQuery {
        contentfulOtherPages {
          contactPageBannerImage {
            fluid(maxWidth: 1920) {
              src
            }
          }
        }
      }
    `}
    render={data => {
      const { contactPageBannerImage } = data.contentfulOtherPages;

      return (
        <div style={{ background: "#FFF" }}>
           <Helmet>
            <title>Contact - DP Tile and Stone</title>
            <meta name="description" content="Get in touch with DP Tile and Stone for premium products and services." />
          </Helmet>
          <HeaderComponent page="contact" />
          <div className="contact-main">
            <div className="contact-column">
              <div style={{
                backgroundImage: `url(${contactPageBannerImage.fluid.src})`,
                backgroundSize: "cover",
                backgroundPosition: "center center",
                height: '700px'  // Adjust based on your design needs
              }}></div>
            </div>
            <div className="contact-column">
              <p className="contact-blurb">Contact Us</p>
              <p className="contact-title"> <em>Have questions? Get in Touch</em></p>
              {/* <p className="contact-content">
                We are here to answer any questions you may have about our
                products and services. Reach out to us and we'll respond as
                soon as we can.
              </p> */}
              <ContactForm />
            </div>
          </div>

          <div className="locations-main">
            <div className="location-item">
              <div className="location">
                <p className="location-title2"><em>Showroom</em></p>
                <p className="location-address">
                  <i className="fas fa-map-marker-alt"></i> Unit 1, 13520 Crestwood Pl, <br /> &nbsp;&nbsp;&nbsp;&nbsp; Richmond, BC V6V 2G2
                </p>
                <p>
                  <i className="fas fa-phone"></i> (604) 233-8888
                </p>
                <p>
                  <i className="fas fa-envelope"></i> info@dptile.ca
                </p>
              </div>

              <div className="map">
                <iframe
                  title="Showroom Location"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2886.413401764915!2d-123.0950008!3d49.1906173!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5486794d9db9d5e3%3A0x70d2f46af3bf0e28!2sUnit%201%2C%2013520%20Crestwood%20Pl%2C%20Richmond%2C%20BC%20V6V%202G2%2C%20Canada!5e0!3m2!1sen!2sus!4v1621915242418!5m2!1sen!2sus"
                  style={{ border: 0 }}
                  allowFullScreen=""
                  aria-hidden="false"
                  tabIndex="0"
                ></iframe>
              </div>
            </div>
            <div className="location-item">
            <div className="location">
                <p className="location-title2"><em>Warehouse</em></p>
                <p className="location-address">
                  <i className="fas fa-map-marker-alt"></i> Unit 7 & 8, 13511 Crestwood Pl <br />  &nbsp;&nbsp;&nbsp;&nbsp; Richmond, BC V6X 2E9
                </p>
                <p className="location-address">
                  <i className="fas fa-phone"></i> (604) 370-3188
                </p>
                <p className="location-address">
                  <i className="fas fa-envelope"></i> info@dptile.ca
                </p>
              </div>
              <div className="map">
                <iframe
                  title="Warehouse"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2607.6330335364964!2d-123.07703512401811!3d49.18854967741617!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x548675a1142c6a43%3A0xaf30855f80291a16!2s13511%20Crestwood%20Pl%2C%20Richmond%2C%20BC%20V6V%202E9%2C%20Canada!5e0!3m2!1sen!2sus!4v1719431283158!5m2!1sen!2sus"
                  style={{ border: 0 }}
                  allowFullScreen=""
                  aria-hidden="false"
                  tabIndex="0"
                ></iframe>
              </div>
            </div>
            <div className="location-item">
            <div className="location">
                <p className="location-title2"><em>Victoria Office</em></p>
                <p className="location-address">
                  <i className="fas fa-map-marker-alt"></i> Unit B, 643 David St, <br />   &nbsp;&nbsp;&nbsp;&nbsp; Victoria, BC V8T 2C9
                </p>
                <p className="location-address">
                  <i className="fas fa-phone"></i> (250) 590-5088
                </p>
                <p className="location-address">
                  <i className="fas fa-envelope"></i> info@dptile.ca
                </p>
              </div>

              <div className="map">
                <iframe
                  title="victoria"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2886.413401764915!2d-123.0950008!3d49.1906173!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5486794d9db9d5e3%3A0x70d2f46af3bf0e28!2sUnit%20B%2C%20643%20David%20St%2C%20Victoria%2C%20BC%20V8T%202C9%2C%20Canada!5e0!3m2!1sen!2sus!4v1621915242418!5m2!1sen!2sus"
                  style={{ border: 0 }}
                  allowFullScreen=""
                  aria-hidden="false"
                  tabIndex="0"
                ></iframe>
              </div>
            </div>


          </div>

          <Footer />
        </div>
      );
    }}
  />
);

export default Contact;
